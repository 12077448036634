<template>
  <b-card>
    <b-col class="col-12">
      <b-card-title class="mb-1">
        {{ $t("stats.devices") }}
      </b-card-title>

      <vue-apex-charts
        type="donut"
        :options="donutChart.chartOptions"
        :series="donutChart.series"
      />
    </b-col>
  </b-card>
</template>

<script>
import { BCard, BCardTitle, BCardSubTitle, BCol } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
const chartColors = {
  donut: {
    series1: "#00cfe8",
    series2: "#ff9f43",
    series3: "#ea5455",
    series4: "#28c76f",
    series5: "#6610f2",
    series6: "#ffc107",
    series7: "#e83e8c",
    series8: "#6610f2",
  },
};
export default {
  props: {
    device_categories: {
      type: Array,
    },
    device_events: {
      type: Array,
    },
  },
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCol,
  },
  data() {
    return {
      donutChart: {
        series: this.device_events,
        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          colors: [
            chartColors.donut.series1,
            chartColors.donut.series2,
            chartColors.donut.series3,
            chartColors.donut.series4,
            chartColors.donut.series5,
            chartColors.donut.series6,
            chartColors.donut.series7,
            chartColors.donut.series8,
          ],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                  },
                },
              },
            },
          },
          labels: this.device_categories,
        },
      },
    };
  },
  watch: {
    device_categories: {
      handler() {
        this.donutChart.chartOptions.labels = this.device_categories;
      },
      deep: true,
    },
    device_events: {
      handler() {
        this.donutChart.series = this.device_events;
      },
      deep: true,
    },
  },
};
</script>
