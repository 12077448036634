<template>
  <b-card-code class="col-12" :title="$t('stats.rooms')">
    <b-row>
      <b-col class="col-12">
        <statistic-card-horizontal
          icon="VideoIcon"
          color="primary"
          :statistic="conference"
          :statistic-title="$t('stats.conference')"
          style="max-width:300px"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12">
        <statistic-card-horizontal
          icon="UsersIcon"
          color="success"
          :statistic="meeting_points"
          :statistic-title="$t('stats.meeting_points')"
          style="max-width:300px"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12">
        <statistic-card-horizontal
          icon="ColumnsIcon"
          color="danger"
          :statistic="totems"
          :statistic-title="$t('stats.totems')"
          style="max-width:300px"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12">
        <statistic-card-horizontal
          icon="BookOpenIcon"
          color="warning"
          :statistic="media"
          :statistic-title="$t('stats.media')"
          style="max-width:300px"
        />
      </b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import Ripple from "vue-ripple-directive";
import BCardCode from "@/@core/components/b-card-code/BCardCode.vue";

export default {
  props: [
    "conference_access",
    "meeting_points_access",
    "totems_access",
    "media_access",
  ],
  components: {
    BCardCode,
    StatisticCardHorizontal,
    BCol,
    BRow,
  },
  data() {
    return {
      conference: this.conference_access,
      meeting_points: this.meeting_points_access,
      totems: this.totems_access,
      media: this.media_access,
    };
  },
  directives: {
    Ripple,
  },
  watch: {
    conference_access() {
      this.conference = this.conference_access;
    },
    meeting_points_access() {
      this.meeting_points = this.meeting_points_access;
    },
    totems_access() {
      this.totems = this.totems_access;
    },
    media_access() {
      this.media = this.media_access;
    },
  },
};
</script>

<style></style>
