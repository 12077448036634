<template>
  <div>
    <b-overlay :show="show" rounded="sm">
      <b-row class="match-height">
        <b-col md="6" lg="3">
          <b-card text-variant="center" class="card card-congratulations">
            <b-img
              :src="require('@/assets/images/elements/decore-left.png')"
              class="congratulations-img-left"
            />
            <b-img
              :src="require('@/assets/images/elements/decore-right.png')"
              class="congratulations-img-right"
            />
            <b-avatar variant="primary" size="70" class="shadow mb-2">
              <feather-icon size="28" icon="AwardIcon" />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              {{ $t("stats.main_event") }}
            </h1>
            <b-card-text class="m-auto w-75">
              {{ $t("stats.stats_event") }}
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="6" lg="3">
          <statistic-card-with-area-chart
            icon="UserIcon"
            color="info"
            :statistic="total_visitors"
            :statistic-title="$t('stats.visitors')"
            :chartData="data.visitors"
            class="stats-card"
          />
        </b-col>
        <b-col md="6" lg="3">
          <statistic-card-with-area-chart
            icon="ActivityIcon"
            color="success"
            :statistic="total_interactions"
            :statistic-title="$t('stats.interactions')"
            :chartData="data.interactions"
            class="stats-card"
          />
        </b-col>
        <!-- <b-col md="6" lg="3">
          <statistic-card-with-area-chart
            icon="UserPlusIcon"
            color="danger"
            :statistic="statistics.sign_up"
            :statistic-title="$t('stats.user_registered')"
            :chartData="data.registrations"
            class="stats-card"
          />
        </b-col> -->
      </b-row>
      <b-row>
        <b-col class="col-7">
          <visitorsGraph
            :daily_visitors_dates="daily_visitors_dates"
            :daily_visitors_data="daily_visitors_data"
            :key="render_component"
          />
        </b-col>
        <b-col class="col-5">
          <interactionsGraph
            :daily_interactions_dates="daily_interactions_dates"
            :daily_interactions_data="daily_interactions_data"
            :key="render_component"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-12">
          <standsList
            ref="stand_list"
            :stands_list="stands_list"
            :sum_visits="total_stand_visit"
            :sum_interactions="total_interactions"
            :sum_contacts="sum_contacts"
            :key="render_component"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BAvatar,
  BCardText,
  BRow,
  BCol,
  BOverlay,
} from "bootstrap-vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import visitorsGraph from "./visitors-graph.vue";
import interactionsGraph from "./interactions-graph.vue";
import standsList from "./stands-list.vue";
import donutDevices from "./donut-devices-chart.vue";
import otherRooms from "./statistics-rooms.vue";
import totemMediaGraph from "./totem-media-graph.vue";
import { getAllStands } from "@api/stands";
import { getStatistic } from "@api/statistic";

export default {
  components: {
    BCard,
    BImg,
    BAvatar,
    BCardText,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    visitorsGraph,
    interactionsGraph,
    standsList,
    donutDevices,
    otherRooms,
    totemMediaGraph,
    BOverlay,
  },
  data() {
    return {
      daily_visitors: [],
      total_visitors: 0,
      daily_visitors_dates: [],
      daily_visitors_data: [],
      daily_interactions: [],
      total_interactions: 0,
      daily_interactions_dates: [],
      daily_interactions_data: [],
      stands_list: [],
      total_stand_visit: 0,
      data: {
        //Valori randomici per poter disegnare la curva a scopo grafico.
        visitors: [
          {
            name: "Visite",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
          },
        ],
        interactions: [
          {
            name: "Interazioni",
            data: [10, 41, 35, 49, 25, 69, 62, 101, 45],
          },
        ],
        registrations: [
          {
            name: "Utenti registrati",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 23],
          },
        ],
      },
      render_component: 0,

      statistics: {
        first_visit: 0,
        interactions: 0,
        sign_up: 0,
      },
      render_component: 0,
      sum_visits: 0,
      sum_interactions: 0,
      sum_contacts: 0,
      show: false,
    };
  },
  async created() {
    this.show = true;
    await this.getStandsList();
    this.getStatisticData();
  },
  methods: {
    clerData() {
      this.daily_visitors = [];
      this.total_visitors = 0;
      this.daily_visitors_dates = [];
      this.daily_visitors_data = [];
      this.daily_interactions = [];
      this.total_interactions = 0;
      this.daily_interactions_dates = [];
      this.daily_interactions_data = [];
      this.total_stand_visit = 0;
    },
    getStatisticData() {
      this.show = true;
      this.clerData();
      let start_date = null;
      let end_date = null;
      if (this.rangeDate) {
        let range_date = this.formatDateToTimestamp(this.rangeDate);
        start_date = range_date[0];
        if (range_date.length > 1) {
          end_date = range_date[1];
        }
      }
      let $this = this;
      getStatistic(start_date, end_date)
        .then((data) => {
          data.sort((a, b) =>
            a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0
          );
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            let date = $this.convertTimeStampToDate(element.timestamp);
            if (element.event == "page_visit") {
              $this.total_visitors++;
              let id = $this.daily_visitors.findIndex((x) => x.date === date);
              if (id == -1) {
                let item = {
                  date: date,
                  count: 1,
                };
                $this.daily_visitors.push(item);
              } else {
                $this.daily_visitors[id].count++;
              }
            }
            if (element.event == "stand_visit") {
              $this.total_stand_visit++;
              let stand_index = $this.stands_list.findIndex(
                (x) => x.id === parseInt(element.data.stand_id)
              );
              if (stand_index != -1) {
                $this.stands_list[stand_index].visits++;
              }
            }
            if (element.event == "pinpoint_click") {
              $this.total_interactions++;
              let id = $this.daily_interactions.findIndex(
                (x) => x.date === date
              );
              if (id == -1) {
                let item = {
                  date: date,
                  count: 1,
                };
                $this.daily_interactions.push(item);
              } else {
                $this.daily_interactions[id].count++;
              }
              let stand_index = $this.stands_list.findIndex(
                (x) => x.id === parseInt(element.data.stand_id)
              );
              if (stand_index != -1) {
                $this.stands_list[stand_index].pinpoints.sum_click++;
              }
            }
          }
          $this.setVisitorsGraph();
          $this.setInteractionsGraph();
          $this.forceUpdate();
          $this.show = false;
        })
        .catch((error) => {
          console.log(error);
          $this.show = false;
        });
    },
    convertTimeStampToDate(timestamp) {
      return new Date(timestamp * 1000).toLocaleDateString("it-IT");
    },
    async getStandsList() {
      this.stands_list = [];
      const data = await getAllStands();
      data.sort((a, b) =>
        a.external_ref > b.external_ref
          ? 1
          : b.external_ref > a.external_ref
          ? -1
          : 0
      );
      for (let i = 0; i < data.length; i++) {
        this.stands_list.push({
          id: data[i].id,
          logo_url: data[i].logo_url,
          name: data[i].name,
          external_ref: data[i].external_ref,
          type: data[i].type.label,
          visits: 0,
          pinpoints: {
            type: [],
            click: [],
            sum_click: 0,
          },
          contacts: 0,
        });
      }
      this.$store.commit("set_list", this.stands_list);
    },
    forceUpdate() {
      this.render_component += 1;
    },
    setVisitorsGraph() {
      this.daily_visitors_dates = this.daily_visitors.map(function(item) {
        return item["date"];
      });
      this.daily_visitors_data = this.daily_visitors.map(function(item) {
        return item["count"];
      });
    },
    setInteractionsGraph() {
      this.daily_interactions_dates = this.daily_interactions.map(function(
        item
      ) {
        return item["date"];
      });
      this.daily_interactions_data = this.daily_interactions.map(function(
        item
      ) {
        return item["count"];
      });
    },
    formatDateToTimestamp(date) {
      let date_splitted = date.split(" to ");
      date_splitted[0] = Date.parse(`${date_splitted[0]} 00:00:00`) / 1000;
      if (date_splitted.length > 1) {
        date_splitted[1] = Date.parse(`${date_splitted[1]} 23:59:59`) / 1000;
      }
      return date_splitted;
    },
  },
  computed: {
    rangeDate: function() {
      return this.$store.state.statistics.rangeDate;
    },
  },
  watch: {
    rangeDate: function() {
      this.getStatisticData();
    },
  },
};
</script>
