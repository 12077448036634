<template>
  <b-card :title="$t('stats.interactions')">
    <app-echart-bar :option-data="option" style="width: 100%;" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";

export default {
  props: {
    daily_interactions_data: {
      type: Array,
    },
    daily_interactions_dates: {
      type: Array,
    },
  },
  components: {
    BCard,
    AppEchartBar,
  },
  data() {
    return {
      option: {
        xAxis: [
          {
            data: this.daily_interactions_dates,
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "60px",
        },
        series: [
          {
            type: "bar",
            stack: "advertising",
            color: "#00cfe8",
            data: this.daily_interactions_data,
            barMaxWidth: "20%",
            barMinWidth: "10px",
            itemStyle: {
              barBorderRadius: [10, 10, 0, 0],
              color: "#00cfe8",
            },
          },
        ],
      },
    };
  },
  watch: {
    daily_interactions_data: {
      handler() {
        this.option.series.data = this.daily_interactions_data;
      },
      deep: true,
    },
    daily_interactions_dates: {
      handler() {
        this.option.xAxis = this.daily_interactions_dates;
      },
      deep: true,
    },
  },
};
</script>
