import { api } from "./index";

export const getStatistic = (start = null, end = null) => {
  let url = "statistics";
  if (start) {
    url = `statistics?start=${start}`;
  }
  if (start && end) {
    url = `statistics?start=${start}&end=${end}`;
  }
  return new Promise((resolve, reject) => {
    api
      .get(url)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};
