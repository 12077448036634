<template>
  <b-card class="pin_stats" title="Totem E Media">
    <app-echart-bar :option-data="option" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";

export default {
  props: {
    totems_names: {
      type: Array,
    },
    totems_access_stats: {
      type: Array,
    },
    media_access_stats: {
      type: Array,
    },
  },
  components: {
    BCard,
    AppEchartBar,
  },
  data() {
    return {
      option: {
        xAxis: [
          {
            type: "value",
          },
        ],
        yAxis: [
          {
            type: "category",
            data: this.totems_names,
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "130px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            name: "Totem Workshop",
            type: "bar",
            color: "#29DAC6",
            stack: "advertising",
            data: this.totems_access_stats,
            barWidth: "30%",
            itemStyle: {
              barBorderRadius: [0, 10, 10, 0],
            },
          },
          {
            name: "Media & Press",
            type: "bar",
            color: "#61F2CA",
            stack: "advertising",
            data: this.media_access_stats,
            barWidth: "30%",
            itemStyle: {
              barBorderRadius: [0, 10, 10, 0],
            },
          },
        ],
      },
    };
  },
  watch: {
    totems_names: {
      handler() {
        this.option.yAxis.data = this.totems_names;
      },
      deep: true,
    },
    totems_access_stats: {
      handler() {
        this.option.series.data = this.totems_access_stats;
      },
      deep: true,
    },
    media_access_stats: {
      handler() {
        this.option.series.data = this.media_access_stats;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.pin_stats .echarts {
  width: 100%;
  height: 500px;
}
</style>
