<template>
  <b-card :title="$t('stats.visitors')">
    <app-echart-stacked-area :option-data="option" style="width: 100%;" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppEchartStackedArea from "@core/components/charts/echart/AppEchartStackedArea.vue";

export default {
  props: {
    daily_visitors_data: {
      type: Array,
    },
    daily_visitors_dates: {
      type: Array,
    },
  },
  components: {
    BCard,
    AppEchartStackedArea,
  },
  data() {
    return {
      option: {
        xAxisData: this.daily_visitors_dates,
        series: [
          {
            type: "line",
            stack: "Total",
            color: "#00cfe8",
            areaStyle: {
              color: "#00cfe8",
            },
            showSymbol: false,
            lineStyle: {
              width: 0,
            },
            data: this.daily_visitors_data,
          },
        ],
      },
    };
  },
  watch: {
    daily_visitors_data: {
      handler() {
        this.option.series.data = this.daily_visitors_data;
      },
      deep: true,
    },
    daily_visitors_dates: {
      handler() {
        this.option.xAxisData = this.daily_visitors_dates;
      },
      deep: true,
    },
  },
};
</script>
